// const thehost = '172.20.1.77'
const thehost = 'wrj.lncloud.net'

export const CURRENT_CONFIG = {

  // license
  appId: '126167', // You need to go to the development website to apply.
  appKey: '0a29c15ae36c243bbca15891c081b0d', // You need to go to the development website to apply.
  appLicense: 'ROhBlA9YwPKxwzRXhYzUgkLR21vS96WT0vVqtAht06INxZElwsgx6xOYaHDRrBhNpvBidcKM3QSEHArO97gK+jGBJTASlGIkqafseJqCRy5cY6XiUu+Dh7CvR5v5jFgmSyN7zI39bKVrHp/t9k3nvd6KvteoY1ZHyfFfT+dRQeo=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://' + thehost + '/apis/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'wss://' + thehost + '/wsstest', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  // rtmpURL: 'rtmp://' + thehost + '/live/', // Example: 'rtmp://192.168.1.1/live/'
  rtmpURL: 'rtmp://bpwrjtl.lnyun.com.cn/bpwrj/',
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: '353f2dd71e8cf1668d30a2e08f1bf961',
  amapSecret: '9a5c1fb04580fc830cef9c3fd039d34d',

}
