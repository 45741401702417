/**
 * 下载文件
 * @param data
 * @param fileName
 */
export function downloadFile (data: Blob, fileName: string) {
  const lable = document.createElement('a')
  lable.href = window.URL.createObjectURL(data)
  lable.download = fileName
  lable.click()
  URL.revokeObjectURL(lable.href)
}

/**
 * 下载文件
 * @param data
 * @param fileName
 */
export function downloadUrl (url: string, fileName: string) {
  const lable = document.createElement('a')
  lable.href = url
  lable.download = fileName
  lable.click()
  // const x = new window.XMLHttpRequest();
  // x.open('GET', url, true);
  // x.responseType = 'blob';
  // x.onload = () => {
  //   const url = window.URL.createObjectURL(x.response);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = fileName;
  //   a.click();
  // };
  // x.send();
}
